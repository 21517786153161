.story-list-child {
  border-bottom: 1px gray dashed;
  margin-bottom: 16px;
}

.story-list-child:first-child {
  border-top: 1px gray dashed;
  padding-top: 20px;
}

//.story-list-crop-block {
//  padding: 3px 5px 5px 3px
//}

.story-list-crop-boder {
  //width: 144px;
  // min-height: 180px;
  border: 1px solid gray;
  border-radius: 4px;
  overflow: hidden;
}

//.story-list-crop {
//  position: absolute;
//  top:      0px;
//  left:     0px;
//  bottom:   0px;
//  right:    0px;
//}

//.story-list-crop img {
//  //margin: -18px 0 0 -12px;
//}

//.story-list-crop-container {
//  position: relative;
//}
//.story-list-crop-container:before {
//  content:     "";
//  display:     block;
//  padding-top: 60%;
//}

.story-list-img-container {
  width: 80px;
  //height: 322px;
  text-align: center;
}



.story-list-img {
  width: 100%;
}

.story-list-img-container {
  text-align: center;
}
.story-child-last-update-child {
  display: inline-block;
  min-width: 100px;
}

.story-search-container {
  padding-bottom: 20px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.story-search-img-container {
  width: 106px;
  //height: 322px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.story-search-detail-container {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  width: calc(100% - 110px);
  & .rm-text-wrapper .rm-text-container {
    margin-bottom: 5px;
  } 
  & .story-child-author {
    width: calc(100% - 86px);
    vertical-align: top;
  }
}

