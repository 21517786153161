.signin-form {
  & .input-group {
    padding-top: 20px;
    padding-bottom: 5px;
  }

  & button {
    margin-top: 20px;
  }
  .error-message {
    color: red;
    padding-left: 41px;
  }
}
