.rm-text-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // & p {
  //   display: block !important;
  // }
}

.rm-overflow-hidden {
  overflow: hidden;
}

.rm-less-button,
.rm-more-button {
  border: 1px green dashed;
  background-color: aliceblue;
}

