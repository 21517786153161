.df-homepage {
  width: 112px;
}

.nav-bar-container {
  background-color: #fff;
  border-bottom: 1px solid #c8ced3;
}

.nav-bar-default {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
