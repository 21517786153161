.ReactTags__suggestions {
  z-index: 2;
}

.ReactTags__tagInputField {
  padding-left: 10px;
  border-radius: 4px;
}

.search-list-tag-block {
  padding-top: 5px;
  padding-bottom: 10px;
}
