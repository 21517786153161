.origin-replace-list-origin-id {
  display:inline-block;
  padding-right: 10px;
}

.origin-replace-list-type {
  display:inline-block;
  padding-right: 10px;
}

.origin-replace-list-name-block {
  display:inline-block;
  padding-right: 10px;
}

.origin-replace-list-name {
  display:inline-block;
  padding-right: 10px;
}
