.display-none {
  display: none;
}


.disabled-link {
  pointer-events: none;
  color: gray;
}

.text-right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.vertical-middle-sp {
  vertical-align: middle;
  hight: 100%;
  display: inline-block;
}

.flex-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.story-list-row-overlay-block {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.story-list-col-overlay-block {
  border-radius: 6px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.story-list-col-overlay-block .cover-image-link {
  min-height: 219px;
  display: inline-block;
}

.overlay-text{
  position: absolute;
  left: 5px;
  bottom: 0px;
  right: 5px;
  color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 4px;
  text-shadow: 1px 2px 2px #000;
  font-size: 12px;
  display: block;
  margin-top: 2px;
  text-align: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.overlay-state{
    position: absolute;
    right: 14px;
    top: 0px;
    padding: 4px 0px;
    text-shadow: 1px 2px 2px #000;
}

.overlay-state-body {
  padding-top: 6px;
  padding-right: 4px;
}

.story-overlay-img {
  width: 100%;
  margin-top: 10px;
  border-radius: 6px;
}

.story-state-border {
  border-left: 6px solid rgba(0, 0, 0, 0.6);
  border-bottom: 6px solid rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 45px;
  height: 50px;
  width: 50px;
}

.story-list-ovelay-empty {
  text-align: center;
  width: 100%;
}

.scrollarea-content {
  touch-action: pan-y pinch-zoom !important;
}

