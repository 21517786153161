.command {
  padding-top: 10px;
  padding-bottom: 10px;
}
.command.active span{
  background: yellow;
}

.command-label {
  color: lightseagreen;
}
