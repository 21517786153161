html, body {
  height: 100%;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.fb-comments iframe {
  width: 100% !important;
}


//div#root {
//  overflow: auto;
//  -webkit-overflow-scrolling: touch;
//}
//
//div.app {
//  position: relative;
//  -webkit-transform: translate3d(0,0,0);
//}


//.is-scrollLocked {
//   overflow: hidden;
//}

.is-scrollLocked {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
}

.is-momentumScrollable {
   -webkit-overflow-scrolling: touch;
}


#root {
  //height: auto !important;
}

//#root {
//  opacity: 0;
//  position: fixed;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  z-index: -1;
//  overflow-y: scroll;
//}

//.app {
//  z-index: 2;
//}



.main .container-fluid {
  padding: 0 10px;
}

.app-body {
  margin-top: 0px;
  //-webkit-transform: translate3d(0,0,0);
}

.breadcrumb {
  position: unset;

}

.rm-more-button, .rm-less-button {
  border-radius: 4px;
}
