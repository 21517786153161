// If you want to add something do it here
@import "event";
@import "page/common/reactTags";
@import "page/common/content";
@import "page/common/read-more-less";
@import "page/common/list";
@import "page/base";
@import "page/common/story-child";
@import "page/common/text-plus";
@import "page/command";
@import "page/common";
@import "page/chap";
@import "page/default-header";
@import "page/home";
@import "page/origin-replace-list";
@import "page/story";
@import "page/sigin";
@import "page/search-list";
// Here you can add other styles

.main-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}
.home-story-list-item {
  text-align: center;
  border: 1px solid #c8ced3;
  border-radius: 6px;
  padding: 5px 0px 6px 0px;
  margin-right: 5px;
  display: inline-block;
}
.paging-area {
  padding: 10px 5px;
}

.search-block {
  z-index: 9;
  position: absolute;
  top: 42px;
  right: 0px;
  left: 2px;
  text-align: left;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0 2px 10px #ababab;
  padding-left: 0px;
}

.search-block-child {
  padding: 5px 5px 5px 10px;
  border: 0.1px solid #ddd;
}

.list-paging-container {
  text-align: center;
}

.list-paging-custom {
  display: inline-flex !important;
  margin-left: auto;
  margin-right: auto;
}
.text-align-left {
  text-align: left;
}

.app-header .nav-item {
  min-width: 86px;
}

.story-img {
  height: 330px;
  width: 220px;
}

.story-tag-group {
  padding: 0px 0px;
}

.story-chapList-title {
  font-size: 18px;
  border-bottom: 1px solid gray;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.story-state {
  padding: 1px 4px;
}

.margin-left-5px {
  margin-left: 5px;
}

.story-tag {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid green;
  display: inline-block;
}

.drop-nav-area-x3 {
  width: 300px;
}

.drop-nav-area-x3-child {
  //min-width: 133px !important;
  width: 140px !important;
  display: inline-block !important;
  text-align: left !important;
}

.origin-tag {
  display: inline-flex;
  padding-right: 5px;
}

.upload-container {
  text-align: center;
  background-color: rgb(206, 213, 223);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.upload-card {
  background-color: white;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.circle-base {
  width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%;
}

.pagination-menu {
  display: block;
}

.fa-battery-full {
  color: white;
}
.fa-battery-1 {
  color: red;
}

.list-paging-container {
}
